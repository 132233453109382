<template>
  <nh-drag-drop-layout class="config-print-page" :drag-items-options="dragItemsOptions" />
</template>
<script>
import loadingMixin from '@thales/loading';
import shipperGoodsStockGenerate from './api';

export default {
  mixins: [loadingMixin],
  // data() {
  //   return {
  //     loading: {
  //       refreshInventory: false,
  //     },
  //   };
  // },
  data() {
    return {
      dragItemsOptions: [
        // 'text',
        // 'table',
        'goodsBill',
      ],
    };
  },
  methods: {
    async  refreshInventory() {
      await shipperGoodsStockGenerate();
      this.$message({ type: 'success', message: '刷新成功！' });
    },
  },
};
</script>
<style lang="scss" scoped>
.config-print-page {
  height: 100%;
  // background-color: red;
}
</style>
